@media screen and (max-width: 290px) {
    .attach-right {
        left: auto !important;
        right: 32px !important;
        top: 170px !important;
    }
}

@media screen and (min-width: 290px) and (max-width: 480px) {
    .attach-right {
        left: auto !important;
        right: 26px !important;
        top: 170px !important;
    }
}

@media screen and (max-width: 374px) {
    .hide-small {
        display: none !important;
    }
}
