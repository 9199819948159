.tag-cloud__container {
    position: fixed;
    display: inline-block;
    cursor: default;
}

.tag-cloud__more {
    padding: 0.5rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    background: #FFFFFF;
    border-radius: 0.5rem;
    z-index: 100;
}
