.card__selector {
    text-align: justify;
    max-width: 100%;
    display: block;
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
}

.card__selector span, p, ul, li, h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
}

.card__selector p {
    color: #333333;
    margin: 0.5rem 0;
}

.card__selector ul {
    list-style-type: circle;
}

.card__selector li {
    margin-left: 2rem;
    color: #333333;
    font-size: 1rem;
}

.ant-card-meta-title {
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
    white-space: normal !important;
}

.card__selector > .ant-card-body {
    padding: 1rem 1rem 1rem 0;
}

.avatar {
    width: 64px;
    height: 64px;
}

@media screen and (min-width: 768px) {
    .mobile-only {
        display: none !important;
    }

    .card__selector > .ant-card-body {
        padding: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .ant-card-meta-avatar {
        display: none !important;
    }
}
