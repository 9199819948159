.aiassistant__sider-entry {
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    max-width: 300px;
    width: 100%;
    overflow: hidden;
    display: inline-block !important;
}

.sider {
    min-height: calc(100vh - 480px);
    max-height: calc(100vh - 480px);
    overflow: auto;
}

@media screen and (min-width: 1025px) {
    .height-restricted {
        min-height: calc(100vh - 536px) !important;
        max-height: calc(100vh - 536px) !important;
        overflow: auto;
    }

    .height-restricted-new {
        min-height: calc(100vh - 584px) !important;
        max-height: calc(100vh - 584px) !important;
        overflow: auto;
    }
}

@media screen and (max-width: 1024px) {
    .height-restricted {
        min-height: calc(50vh);
        max-height: calc(50vh);
        overflow: auto;
    }

    .height-restricted-new {
        min-height: calc(50vh);
        max-height: calc(50vh);
        overflow: auto;
    }
}
