@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, h1, h2, h3, h4, h5, p, ul, button, pre {
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
}

#root {
    background-color: #F5F5F5;
}

.bg-offwhite-important {
    background: #FBFBFB !important;
}

.p-4-important {
    padding: 1rem !important;
}

.p-0-important {
    padding: 0 !important;
}

.no-border {
    border: 0 !important;
}

.search > .ant-input-wrapper > .ant-input-affix-wrapper,
.search > .ant-input-wrapper > .ant-input-group-addon > .ant-input-search-button {
    border-radius: 0 !important;
    border-start-end-radius: 0 !important;
    border-start-start-radius: 0 !important;
}

.ant-select, .ant-select-selector {
    border-radius: 0 !important;
    border-start-end-radius: 0 !important;
    border-start-start-radius: 0 !important;
}

.ant-input-search-button > .anticon {
    vertical-align: 0;
}

.side-menu * .ant-menu-item-selected, .side-menu * .ant-menu-item-active {
    background: #E9E9E9 !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.bg-none-important {
    background: none !important;
}

.layout {
    min-height: 100vh;
    background: rgb(209, 82, 46) !important;
    background: linear-gradient(353deg, rgba(209, 82, 46, 1) 0%, rgba(66, 22, 49, 1) 100%) !important;
    background-size: 100vw 182px !important;
    background-repeat: no-repeat !important;
}

.layout-home {
    min-height: 100vh;
    background: rgb(209, 82, 46) !important;
    background: linear-gradient(353deg, rgba(209, 82, 46, 1) 0%, rgba(66, 22, 49, 1) 100%) !important;
    background-size: 100vw 182px !important;
    background-repeat: no-repeat !important;
}

.content-searchbar {
    margin: 1rem 15vw;
    width: 50vw !important;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2rem;
    align-self: center;
    border: 1px solid #AAA;
}

.searchbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.searchbar img {
    max-width: 200px;
    max-height: 100px;
}

.content-searchbar input {
    background: rgba(255, 255, 255, 0);
    padding: 0.5rem !important;
    color: #222 !important;
}

.content-searchbar input::placeholder {
    color: #555 !important;
}

.ant-menu-sub {
    background: #49000c !important;
}

.content-options {
    padding: 0 10vw;
    margin: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.content-options__card-icon {
    font-size: 3rem;
}

.content-options > .ant-card:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.content-options > .ant-card {
    border-radius: 0 !important;
    flex: 1 0;
    align-self: stretch;
    height: 184px;
    margin: 1.5vh 0;
    transition: height 0.2s ease-in-out, margin 0.2s ease-in-out;
    border: 1px solid #dddddd !important;
}

.active-underline {
    box-shadow: inset 0 -5px 0 #D1522E !important;
}

.active-overline {
    box-shadow: inset 0 3px 0 #D1522E !important;
}

.content-options > .ant-card:hover {
    height: 208px !important;
    border-radius: 8px !important;
    margin: 0;
}

.content-options > .ant-card > .ant-card-body > h1 {
    color: #555 !important;
    font-size: 1.1rem;
    text-align: center;
}

.content-options > .ant-card:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.content-options .ant-card-cover {
    margin-top: 2rem;
}

.site-search {
    left: 25vw !important;
    width: 50% !important;
}

.px-4-important-mob {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

@media screen and (min-width: 1024px) {
    .mobile-only {
        display: none !important;
    }

    .footer {
        display: none !important;
    }
}


@media screen and (max-width: 1023px) {
    .layout-home {
        background-size: 100vw 150px !important;
    }

    .layout {
        background-size: 100vw 64px !important;
    }

    .searchbar img {
        display: none;
    }

    .news__links {
        display: none;
    }

    .ant-menu-dark {
        background: none !important;
    }

    .content-searchbar {
        width: calc(100vw - 4rem) !important;
        margin: 1rem;
    }

    .content-searchbar input {
        color: #fff !important;
    }

    .content-searchbar input::placeholder {
        color: #ccc !important;
    }

    .site-search {
        left: 5vw !important;
        max-width: 90vw !important;
        min-width: 90vw !important;
        width: 90vw !important;
    }

    .content-options {
        display: none;
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        z-index: 200;
        padding: 0 !important;
        max-width: 100%;
        border-top: solid 2px #D1522E !important;
    }

    .footer > .ant-card {
        flex: 1;
        border-radius: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
    }

    .footer__card-icon {
        font-size: 1.25rem;
    }

    .footer > .ant-card > .ant-card-body {
        display: none;
    }

    .footer > .ant-card:hover, .active-overline {
        box-shadow: inset 0 3px 0 #D1522E !important;
    }

    .px-4-important-mob {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

@media screen and (max-width: 480px) {
    .ant-picker-panels {
        display: flex;
        flex-direction: column;
    }

    .ant-picker-panel-container {
        margin-left: 1rem !important;
    }
}

.ant-picker-panel-container {
    margin-left: 1rem !important;
}

.google-search {
    color: black;
    cursor: pointer;
    display: block;
}

.google-search > img {
    height: 0.75rem;
    margin-right: 0.25rem;
    display: inline;
}

.site-layout-content > .ant-card-body {
    padding: 0 !important;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .container {
        max-width: 100% !important;
    }
}
